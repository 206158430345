.modal .panel_control {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal .panel_control button {
    display: flex;
    column-gap: 6px;
    align-items: center;

    font-family: "Itim", sans-serif;
    font-weight: 400;
    font-size: 20px;

    text-shadow: 0 0 1px #14141415;
    transition: all .25s;
    color: #90ABFF;
}

.modal .panel_control button:hover {
    color: #444;
    opacity: .5;
}

.modal .panel_control span {
    padding-bottom: 2px;
    font-family: "Dosis", sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.modal .panel_control .btn_validation {
    height: 44px;
    margin-top: 2px;
    padding: 0 24px 2px;

    color: #fff;
    border: solid 2px #fff;
    background-color: #90ABFF;
    transition: all .2s;

    font-family: "Dosis", sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.modal .panel_control .btn_validation:hover {
    opacity: 1;
    color: #90ABFF;
    background-color: #fff;
    border: solid 2px #90ABFFbb;
}