.mdl_stop_process {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-bottom: 70px;
    background-color: #90ABFF30;

    position: fixed;
    left: 0;
    top: 0;
}

.mdl_stop_process .content {
    display: flex;
    row-gap: 20px;
    align-items: center;
    flex-direction: column;
    background-color: #90ABFFee;
    box-shadow: 0 0 8px 8px #90ABFFee;
    padding: 30px 80px 40px;
}

.mdl_stop_process span {
    text-shadow: 0 0 1px #00000055;
    font-family: 'Itim', sans-serif;
    font-weight: 400;
    font-size: 36px;
    color: #fff;
}

.mdl_stop_process p {
    width: 320px;
    text-shadow: 0 0 1px #00000022;
    font-family: 'Glory', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
}

.mdl_stop_process .panel {
    display: flex;
    column-gap: 100px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.mdl_stop_process button {
    width: 75px;
    height: 30px;

    transition: all .3s;
    padding-bottom: 2px;
    border: solid 2px #fff;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
}

.mdl_stop_process button:hover {
    background-color: #fff;
    color: #90ABFF;
}