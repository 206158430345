.achievment_selector {
    height: 100%;
    max-height: 100%;
    padding: 40px 0 0;
    position: relative;
}

.achievment_selector h2 {
    height: 30px;
    color: #90ABFF;
    text-shadow: 0 0 1px #00000020;

    font-family: 'Glory', sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.achievment_selector .select_list {
    width: 100%;
    max-height: 442px;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
    
    gap: 24px;
    display: grid;
    padding: 15px 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.achievment_selector li button {
    width: 100%;
    height: 52px;

    display: flex;
    align-items: center;

    box-shadow: 0 0 4px #14141405;
    border: solid 1px #14141415;
    background-color: #F59B4815;
    padding: 0 14px;
}

.achievment_selector .first button {
    background-color: #90ABFF15;
}

.achievment_selector .product button {
    background-color: #d4121715;
}

.achievment_selector li .actif {
    background-color: #F59B4855;
}

.achievment_selector .first .actif {
    background-color: #90ABFF55;
}

.achievment_selector li p {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    font-family: 'Glory', sans-serif;
    font-weight: 500;
    font-size: 18px;

    color: #141414bb;
    text-align: left;
}

.achievment_selector li i {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    border: solid 1px #14141425;

    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
}

.achievment_selector li .no_value {
    width: 250px;
    display: flex;
    justify-content: center;

    opacity: .4;
    color: #90ABFF;
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
    font-size: 32px;

    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
}

.achievment_selector .edit_category {
    width: 250px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
    font-size: 20px;

    background-color: #90ABFFbb;
    margin: 80px auto;
    color: #fff;
}