.modal .part_3 {
    column-gap: 80px;
}

.modal .part_3 h2 {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */

.modal .part_3 .table_ref {
    width: calc(50% - 20px);
    min-width: calc(50% - 20px);
    
    column-gap: 20px;
    display: flex;
}

.modal .part_3 .ctn_search_bar {
    width: 100%;
    
    display: flex;
    row-gap: 4px;
    flex-direction: column;
}

.modal .part_3 .search_bar {
    width: 100%;
    height: 39px;
    min-height: 39px;

    display: flex;
    margin-top: 8px;
    position: relative;
}

.modal .part_3 .search_bar input {
    width: 100%;
    height: 100%;
    
    display: flex;
    cursor: pointer;
    transition: all .3s;
    
    border: solid 2px #90ABFF88;
    background-color: #90ABFF15;
    
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    padding: 0 55px 0 15px;
    border-radius: 20px;
}

.modal .part_3 .search_bar input:hover,
.modal .part_3 .search_bar input:focus {
    border: solid 2px #90ABFF;
    background-color: #fff;
}

.modal .part_3 .search_bar i {
    transform: translate(-50%, -50%);
    position: absolute;
    right: 18px;
    top: 50%;
}

.modal .part_3 .search_bar p {
    width: 100%;
    height: 100%;

    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;

    color: #141414a0;
    padding: 0 15px;

    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal .part_3 .search_bar span {
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #14141470;
}

.modal .part_3 .search_list {
    row-gap: 8px;
    display: flex;
    max-height: 450px;
    
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
    
    padding: 8px 15px;
    flex-direction: column;
}

.modal .part_3 .search_list li {
    width: 100%;
    height: 30px;
    min-height: 30px;

    display: flex;
    align-items: center;

    padding: 0 15px;
    border-radius: 4px;
    background-color: #90ABFF88;
    justify-content: space-between;
}

.modal .part_3 .search_list p {
    color: #141414bb;
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal .part_3 .search_list button {
    width: 22px;
    height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    color: #141414bb;

    transition: all .25s;
    font-size: 14px;
    opacity: 0;
}

.modal .part_3 .search_list li:hover button {
    opacity: 1;
}

.modal .part_3 .search_list li:hover button:hover {
    color: #d41217bb;
}


/* ------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------- */


.modal .part_3 .table_ref .references_list {
    width: 100%;
    max-height: 504px;

    display: flex;
    flex-direction: column;
    
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    padding: 0 10px 8px;
    row-gap: 12px;
}

.modal .part_3 .table_ref .references_list li {
    width: 100%;
    height: 39px;
    min-height: 39px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border: solid 1px #14141415;
    background-color: #90ABFF15;
    
    user-select: none;
    padding: 0 15px;
}

.modal .part_3 .table_ref .references_list li p {
    width: 300px;
    height: 39px;

    display: flex;
    align-items: center;
    color: #141414bb;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal .part_3 .table_ref .references_list .btn_del_file {
    width: 30px;
    height: 30px;
    
    display: none;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 2px #14141415;
    border: solid 1px #14141415;
    background-color: #fff;
    border-radius: 5px;

    cursor: pointer;
    padding: 5px;
}

.modal .part_3 .table_ref .references_list li:hover .btn_del_file {
    display: flex;
}

.modal .part_3 .table_ref .references_list .btn_del_file img {
    width: 100%;
    height: 100%;
}