.modal_update_references .ctn_search_bar {
    position: relative;
}

.modal_update_references .search_bar {
    width: 400px;
    height: 39px;
    min-height: 39px;

    display: flex;
    position: relative;
}

.modal_update_references .search_bar input {
    width: 100%;
    height: 100%;
    
    display: flex;
    cursor: pointer;
    transition: all .3s;
    
    border: solid 2px #90ABFF88;
    background-color: #90ABFF15;
    
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    padding: 0 55px 0 15px;
    border-radius: 20px;
}

.modal_update_references .search_bar input:hover,
.modal_update_references .search_bar input:focus {
    border: solid 2px #90ABFF;
    background-color: #fff;
}

.modal_update_references .search_bar i {
    transform: translate(-50%, -50%);
    position: absolute;
    right: 18px;
    top: 50%;
}

.modal_update_references .search_bar p {
    width: 100%;
    height: 100%;

    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;

    color: #141414a0;
    padding: 0 15px;

    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_update_references .search_bar span {
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #14141470;
}

.modal_update_references .search_list {
    width: 380px;
    height: 450px;
    max-height: 450px;
    
    row-gap: 8px;
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;
    border-right: 7px solid #fff;
    
    margin: 8px 0;
    padding: 0 15px;
    
    background-color: #fff;
    position: absolute;
    z-index: 1;
}

.modal_update_references .search_list li {
    width: 340px;
    height: 30px;
    min-height: 30px;

    display: flex;
    align-items: center;

    padding: 0 15px;
    border-radius: 4px;
    background-color: #90ABFF88;
    justify-content: space-between;
}

.modal_update_references .search_list p {
    color: #141414bb;
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_update_references .search_list button {
    width: 22px;
    height: 22px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    color: #141414bb;

    transition: all .25s;
    font-size: 14px;
    opacity: 0;
}

.modal_update_references .search_list li:hover button {
    opacity: 1;
}

.modal_update_references .search_list li:hover button:hover {
    color: #d41217bb;
}

.modal_update_references form {
    width: 100%;
    max-width: 100%;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    padding: 40px 0;
}

.modal_update_references form h2 {
    width: 400px;
    padding: 0 4px;
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #444;
}

.modal_update_references .ctn_table_ref {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    position: relative;
}

.modal_update_references .table_ref {
    max-width: 100%;
    max-height: 340px;
    
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    justify-content: flex-start;

    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    border-radius: 4px 4px 0 0;
    border: solid 4px #90ABFFbb;
}

.modal_update_references .table_ref ul {
    display: flex;
    border-bottom: solid 4px #90ABFFbb;
}

.modal_update_references .table_ref li {
    width: 255px;
    display: flex;
    position: relative;
    border-right: solid 4px #90ABFFbb;
}

.modal_update_references .table_ref ul:last-child {
    border: none;
}

.modal_update_references .table_ref input {
    height: 40px;
    padding: 15px;
    background-color: #90ABFF22;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.modal_update_references .table_ref .input_schema {
    width: 255px;
    min-width: 255px;
    height: 40px;
    position: relative;
}

.modal_update_references .table_ref .input_schema input {
    width: 255px;
    min-width: 255px;
    height: 100%;

    opacity: 0;
    padding: unset;
    cursor: pointer;
    background-color: #fff;

    position: absolute;
    left: 0;
    top: 0;
}

.modal_update_references .table_ref .input_schema label {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_update_references .table_ref .btn_add_column {
    width: 50px;
    min-width: 50px;
    height: 40px;

    font-size: 22px;
    color: #fff;
    background-color: #90ABFFbb;
}

.modal_update_references .table_ref .btn_del_row {
    width: 30px;
    height: 30px;

    opacity: 0;
    padding: 4px;
    transition: all .3s;
    background-color: #fff;
    
    border: solid 1px #80808044;
    border-radius: 4px;

    transform: translateY(-50%);
    position: absolute;
    
    right: 12px;
    top: 50%;
}

.modal_update_references .table_ref li:hover .btn_del_row {
    opacity: 1;
}

.modal_update_references .table_ref img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal_update_references .btn_add_row {
    width: 100%;
    height: 40px;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 19px;
    
    background-color: #90ABFFbb;
    border-radius: 0 0 4px 4px;
    padding: 6px 0;
    color: #fff;
}

.modal_update_references .btn_validation {
    height: 60px;
    transition: all .2s;
    margin: 60px auto 0;
}