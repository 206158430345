.input_login {
    display: flex;
    row-gap: 2px;
    flex-direction: column;
}

.input_login label {
    width: 100%;
    color: #fff;

    font-family: 'Jaldi', sans-serif;
    font-weight: 400;
    font-size: 20px;
}

.input_login input {
    width: 100%;
    height: 42px;

    color: #fff;
    padding: 0 10px;
    
    border-radius: 2px;
    background-color: #262626;
    border: solid 2px #ffffff00;

    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 17px;
}

.input_login input::placeholder {
    color: #fff;
    opacity: .35;
}

.input_login input:focus {
    border: solid 2px #F59B48dd;
}

/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {
    
}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {
    
}