.page_login {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #141414;

    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
}

.page_login section {
    display: flex;
    overflow: hidden;
    border-radius: 12px;
    background-color: #111;
    box-shadow: 0 0 2px #ffffff25;
    margin-bottom: 20px;
}

.page_login .background_site {
    width: 100%;
    height: 100%;

    opacity: .5;
    display: flex;
    object-fit: cover;

    position: fixed;
    z-index: -2;

    left: 0;
    top: 0;
}

/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */

.page_login .ctn_presentation {
    width: 470px;
    row-gap: 20px;

    display: flex;
    overflow: hidden;
    flex-direction: column;

    position: relative;
    background-color: #000;
    padding: 30px 24px 30px 40px;
}

.page_login .ctn_presentation span,
.page_login .ctn_presentation p {
    font-family: 'Jura', sans-serif;
    color: #fff;
    
    position: relative;
    z-index: 1;
}

.page_login .ctn_presentation span {
    font-weight: 700;
    font-size: 28px;
}

.page_login .ctn_presentation p {
    font-weight: 400;
    font-size: 18px;
}

.page_login .ctn_presentation .space {
    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 0;
    opacity: .5;

    left: 50%;
    top: 50%;
}

.page_login .ctn_presentation .lottie_login_moon {
    transform: translate(-20%, -50%) scale(2);
    position: absolute;
    z-index: 1;
    top: 130%;
}

.page_login .ctn_presentation .lottie_login {
    transform: scale(.3);
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50px;
}

/* ------------------------------------------------------------------- */
/* ------------------------------------------------------------------- */

.page_login form {
    row-gap: 25px;

    display: flex;
    flex-direction: column;

    padding: 70px;
    position: relative;
}

.page_login form span {
    text-align: center;
    color: #fff;

    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 26px;
}

.page_login form .grid {
    gap: 15px;
    display: grid;
    grid-template-columns: 1fr;
}

.page_login form .input_text {
    width: 300px;
}

.page_login form .msg_error {
    font-family: 'Dosis', sans-serif;
    font-weight: 600;
    font-size: 16px;

    letter-spacing: 1.5px;
    user-select: none;
    color: #F59B48;
}

.page_login form .btn_submit {
    width: 140px;
    height: 38px;

    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;

    font-family: 'Barlow', sans-serif;
    font-weight: 400;
    font-size: 20px;

    margin: 35px auto 0;
    transition: all .3s;
    border-radius: 4px;

    background-color: #fff;
    color: #1E1E1E;
    opacity: .9;
}

.page_login form .btn_submit:hover {
    opacity: .5;
}


/* ------------------------------------------------------------------- */
/* ----------------------- Format tablette --------------------------- */

@media screen and (max-width: 1200px) {}

/* ------------------------------------------------------------------- */
/* ------------------------ Format mobile ---------------------------- */

@media screen and (max-width: 767px) {}