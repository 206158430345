.modal_update_services .input_video {
    width: 40%;
}

.modal_update_services .input_video .inp_video {
    width: 100%;

    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    background-color: #90ABFF15;
    border: solid 1px #14141410;
    padding: 10px 14px;
}

.modal_update_services .inp_video::placeholder {
    color: #14141466;
}

.modal_update_services .inp_video:focus {
    border: solid 1px #90ABFF;
}

.modal_update_services .inp_video {
    height: 36px;
}