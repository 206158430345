.modal_create_references form {
    width: unset;
    max-width: 100%;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 0;
}

.modal_create_references form h2 {
    padding: 0 4px;
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #444;
}

.modal_create_references .table_ref {
    max-width: 100%;
    
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    display: flex;
    flex-direction: column;

    border-radius: 4px;
    border: solid 4px #90ABFFbb;
}

.modal_create_references .table_ref ul {
    display: flex;
    border-bottom: solid 4px #90ABFFbb;
}

.modal_create_references .table_ref li {
    width: 255px;
    min-width: 255px;
    display: flex;
    position: relative;
    border-right: solid 4px #90ABFFbb;
}

.modal_create_references .table_ref ul:last-child {
    border: none;
}

.modal_create_references .table_ref input {
    height: 40px;
    padding: 15px;
    background-color: #90ABFF22;

    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 16px;
}

.modal_create_references .table_ref .input_schema {
    width: 100%;
    height: 40px;
    position: relative;
}

.modal_create_references .table_ref .input_schema input {
    width: 100%;
    height: 100%;

    opacity: 0;
    padding: unset;
    cursor: pointer;
    background-color: #fff;

    position: absolute;
    left: 0;
    top: 0;
}

.modal_create_references .table_ref .input_schema label {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_create_references .table_ref .btn_add_column {
    width: 50px;
    height: 40px;

    font-size: 22px;
    color: #fff;
    background-color: #90ABFFbb;
}

.modal_create_references .table_ref .btn_del_row {
    width: 30px;
    height: 30px;

    opacity: 0;
    padding: 4px;
    transition: all .3s;
    background-color: #fff;
    
    border: solid 1px #80808044;
    border-radius: 4px;

    transform: translateY(-50%);
    position: absolute;
    
    right: 12px;
    top: 50%;
}

.modal_create_references .table_ref li:hover .btn_del_row {
    opacity: 1;
}

.modal_create_references .table_ref img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.modal_create_references .table_ref .btn_add_row {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 19px;
    
    background-color: #90ABFFbb;
    padding: 6px 0;
    color: #fff;
}

.modal_create_references .btn_validation {
    height: 50px;
    transition: all .2s;
    margin: 60px auto 0;
}