header {
    width: 200px;
    height: 100vh;
 
    display: flex;
    align-items: center;
    flex-direction: column;

    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
}

header .btn_home {
    width: 140px;
    height: 140px;

    overflow: hidden;
    transition: all .3s;

    padding: 10px;
    margin: 30px 0;

    border-radius: 80px;
    background-color: #fff;
}

header .btn_home:hover {
    opacity: .7;
}

header .btn_home img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

header ul {
    width: 100%;
    display: flex;
    flex-direction: column;
}

header button {
    width: 100%;
    height: 65px;

    padding: 0 15px;
    position: relative;

    display: flex;
    column-gap: 8px;
    align-items: center;

    text-shadow: 0 0 1px #00000050;
    font-family: 'Glory', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}

header button img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

header .actif {
    background-color: #6482df;
}