.input_list {
    width: 100%;
}

.input_list input {
    width: 100%;
    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    background-color: #90ABFF15;
    border: solid 1px #14141410;
    padding: 10px 14px;
}

.input_list input::placeholder {
    color: #14141466;
}

.input_list input:focus {
    border: solid 1px #90ABFF;
}