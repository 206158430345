.lang_selector {
    display: flex;
    column-gap: 12px;
    position: absolute;
}

.lang_selector button {
    width: 35px;
    height: 35px;
    
    opacity: .4;
    transition: all .2s;

    overflow: hidden;
    border-radius: 22px;
}

.lang_selector button:hover {
    opacity: 1;
}

.lang_selector img {
    height: 100%;
    object-fit: cover;
}

.lang_selector .actif {
    box-shadow: 0 0 6px 6px #90ABFF88;
    opacity: 1;
}