.modal_create_services .part_2 {
    display: flex;
    column-gap: 80px;
}

.modal_create_services h2 {
    font-family: 'Jaldi', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #444;
}

.modal_create_services .input_files {
    width: calc(50% - 160px);
    
    display: flex;
    row-gap: 4px;
    flex-direction: column;
}

.modal_create_services .inp_file {
    width: 100%;
    height: 39px;
    display: flex;
    
    border: dashed 2px #90ABFF88;
    background-color: #90ABFF15;
    border-radius: 20px;

    transition: all .3s;
    position: relative;
}

.modal_create_services .inp_file:hover {
    opacity: .5;
}

.modal_create_services .inp_file input {
    width: 100%;
    height: 100%;
    
    opacity: 0;
    cursor: pointer;

    position: absolute;
    left: 0;
    top: 0;
}

.modal_create_services .inp_file p {
    width: 100%;
    height: 100%;

    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;

    color: #141414a0;
    padding: 0 15px;

    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_create_services .inp_file span {
    
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #14141470;
}

.modal_create_services .input_files .files_list {
    width: 100%;
    max-height: 355px;

    display: flex;
    flex-direction: column;
    
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #90ABFF #44444415;

    padding: 8px 0;
    row-gap: 12px;
}

.modal_create_services .input_files .files_list li {
    width: calc(100% - 30px);
    height: 39px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border: solid 1px #14141415;
    background-color: #90ABFF15;
    
    user-select: none;
    padding: 0 15px;
    margin: 0 15px;
}

.modal_create_services .input_files .files_list li p {
    width: 300px;
    height: 39px;

    display: flex;
    align-items: center;
    color: #141414bb;
    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    font-family: 'Itim', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.modal_create_services .input_files .files_list .btn_del_file {
    width: 30px;
    height: 30px;
    
    display: none;
    align-items: center;
    justify-content: center;

    box-shadow: 0 0 2px #14141415;
    border: solid 1px #14141415;
    background-color: #fff;
    border-radius: 5px;

    cursor: pointer;
    padding: 5px;
}

.modal_create_services .input_files .files_list li:hover .btn_del_file {
    display: flex;
}

.modal_create_services .input_files .files_list .btn_del_file img {
    width: 100%;
    height: 100%;
}

.modal_create_services .input_video {
    width: 40%;
}

.modal_create_services .input_video .inp_video {
    width: 100%;

    font-family: 'Barlow', sans-serif;
    font-weight: 600;
    font-size: 14px;

    background-color: #90ABFF15;
    border: solid 1px #14141410;
    padding: 10px 14px;
}

.modal_create_services .inp_video::placeholder {
    color: #14141466;
}

.modal_create_services .inp_video:focus {
    border: solid 1px #90ABFF;
}

.modal_create_services .inp_video {
    height: 36px;
}