.product_selector li img {
    width: 30px;
    height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: 4px;
    border-radius: 4px;
    background-color: #fff;
    border: solid 1px #14141425;
    
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    opacity: 0;
}

.product_selector li:hover img {
    transition: all .3s;
    opacity: 1;
}

.product_selector li:hover img:hover {
    background-color: #d4121720;
    opacity: .6;
}