.loader {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    position: absolute;
    z-index: 100;
    top: 0;
}

.loader-content {
    width: calc(100% - 200px);
    height: calc(100vh - 70px);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    background-color: #90ABFF33;
    border-radius: 10px 0 0 0;
    padding: 0 4% 4% 0;
}

.loader-content .fas {
    display: flex;
    border-radius: 30px;
    animation: spin 1s 0s infinite linear;
    text-shadow: 0 0 2px #14141488;
    color: #90ABFF;
    font-size: 60px;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}