*,
::after,
::before {
    margin: 0;
    padding: 0;

    outline: none;
    box-sizing: border-box;
}

body {
    background-color: #90ABFF;
    position: relative;
    overflow-x: hidden;
}

.page {
    display: flex;
    position: relative;
    flex-direction: column;

    width: calc(100% - 200px);
    height: calc(100vh - 70px);
    transform: translate(200px, 70px);

    box-shadow: inset 0 0 8px #14141488;
    background-color: #f8f8f8;
    border-radius: 10px 0 0 0;
    padding: 40px 60px 0;
}

.modal {
    width: 100%;
    height: 100%;

    background-color: #fff;
    padding: 50px 70px 0;

    border-radius: 25px 25px 0 0;
    border: solid 1px #14141410;
    border-top: none;
}

.modal .btn_stop_update {
    width: 120px;
    height: 35px;
    
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: center;
    
    background-color: #d4121744;
    transition: all .2s;
    border-radius: 6px;
    color: #fff;
    
    transform: translateX(-50%);
    position: absolute;
    right: 60px;
    top: 160px;
}

.modal .btn_stop_update:hover {
    background-color: #d41217bb;
}

.modal h1 {
    width: 100%;
    height: 40px;
    color: #90ABFF;
    text-align: center;

    font-family: "Itim", sans-serif;
    font-weight: 400;
    font-size: 32px;
}

.modal form {
    width: 100%;
    height: calc(100% - 160px);
}

.modal .form_part {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 40px;
    position: relative;
}

.modal .part_1 {
    column-gap: 80px;
}

.modal .part_2 {
    display: flex;
    column-gap: 80px;
}

.invisible {
    visibility: hidden;
}

a {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    font-size: 1rem;
    color: black;
}

button {
    font-family: "Roboto", sans-serif;
    background-color: transparent;
    font-size: 1rem;
    cursor: pointer;
    border: none;
}

input,
textarea {
    border: none;
    resize: none;
    background-color: transparent;
}

ul {
    list-style: none;
}